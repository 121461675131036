import { ProductValue } from "./product.value";

export default function doesPlushieHaveBodyParts(
  plushieProduct: ProductValue
): boolean {
  return [
    ProductValue.SELFIE,
    ProductValue.SPECIALITY_COMMISSION,
    ProductValue.PETSIE_CAT,
    ProductValue.PETSIE_DOG,
    ProductValue.HUGGABLES_CAT,
    ProductValue.HUGGABLES_DOG,
    ProductValue.HUGGABLES_OTHER,
    ProductValue.FOREVERS_CAT,
    ProductValue.FOREVERS_DOG,
    ProductValue.FOREVERS_OTHER,
    ProductValue.WAGGABLES_DOG,
    ProductValue.PHRASE_PILLOW,
    ProductValue.PILLOW,
    ProductValue.BULK_SAMPLE,
    ProductValue.PILLOW_BULK_SAMPLE,
    ProductValue.BUDDY_PILLOW,
    ProductValue.PRINTED_KEYCHAINS,
    ProductValue.SELFIES_PUPPET,
    ProductValue.CARTOON_PILLOW,
    ProductValue.FELTED_MAGNETS,
    ProductValue.FELTED_ORNAMENTS,
    ProductValue.BOBBLEHEADS,
    ProductValue.FIGURINES,
    ProductValue.PETSIES_BOBBLEHEADS,
    ProductValue.PETSIES_FIGURINES,
    ProductValue.GOLF_HEAD_COVER_DOG,
    ProductValue.GOLF_HEAD_COVER_CAT,
    ProductValue.GOLF_HEAD_COVER_OTHER,
    ProductValue.PLUSHIE_BATCH,
    ProductValue.SHEETZ_FREAKZ,
  ].includes(plushieProduct);
}
