import { ProductValue } from "./product.value";
import { MutableValue } from "../api/mutable-value.interface";

export default class Product implements MutableValue {
  public isNew = true;

  constructor(readonly id: ProductValue, readonly name: string) {}

  get hasBreed(): boolean {
    return [
      ProductValue.FOREVERS_DOG,
      ProductValue.HUGGABLES_DOG,
      ProductValue.PETSIE_DOG,
      ProductValue.GOLF_HEAD_COVER_DOG,
    ].includes(this.id);
  }

  get hasName(): boolean {
    return [
      ProductValue.BULK_SAMPLE,
      ProductValue.FOREVERS_CAT,
      ProductValue.FOREVERS_DOG,
      ProductValue.FOREVERS_OTHER,
      ProductValue.HUGGABLES_CAT,
      ProductValue.HUGGABLES_DOG,
      ProductValue.HUGGABLES_OTHER,
      ProductValue.PETSIE_CAT,
      ProductValue.PETSIE_DOG,
      ProductValue.PETSIE_OTHER,
      ProductValue.PILLOW,
      ProductValue.PILLOW_BULK_SAMPLE,
      ProductValue.WAGGABLES_DOG,
      ProductValue.KEYCHAIN_BULK_SAMPLE,
      ProductValue.PHOTO_PORTRAITS,
    ].includes(this.id);
  }

  get isActive(): boolean {
    return [
      ProductValue.BUDSIE,
      ProductValue.SELFIE,
      ProductValue.BULK_SAMPLE,
      ProductValue.SPECIALITY_COMMISSION,
      ProductValue.FOREVERS_CAT,
      ProductValue.FOREVERS_DOG,
      ProductValue.FOREVERS_OTHER,
      ProductValue.PILLOW,
      ProductValue.PILLOW_BULK_SAMPLE,
      ProductValue.PRINTED_KEYCHAINS,
      ProductValue.PRINTED_MASKS,
      ProductValue.PRINTED_SOCKS,
      ProductValue.BUDSIES_PALS,
      ProductValue.BUDDY_PILLOW,
      ProductValue.PHRASE_PILLOW,
      ProductValue.BUDSIES_PUPPET,
      ProductValue.SELFIES_PUPPET,
      ProductValue.CARTOON_PILLOW,
      ProductValue.FELTED_MAGNETS,
      ProductValue.FELTED_ORNAMENTS,
      ProductValue.CUT_OUT_BLANKETS,
      ProductValue.RENAISSANCE_BLANKETS,
      ProductValue.KEYCHAIN_BULK_SAMPLE,
      ProductValue.BOBBLEHEADS,
      ProductValue.FIGURINES,
      ProductValue.PETSIES_BOBBLEHEADS,
      ProductValue.PETSIES_FIGURINES,
      ProductValue.PAJAMAS,
      ProductValue.GOLF_HEAD_COVER_DOG,
      ProductValue.GOLF_HEAD_COVER_CAT,
      ProductValue.GOLF_HEAD_COVER_OTHER,
      ProductValue.PLUSHIE_BATCH,
      ProductValue.PILLOW_BATCH,
      ProductValue.KEYCHAIN_BATCH,
      ProductValue.GOLF_SHIRTS,
      ProductValue.HAWAIIAN_SHIRTS,
      ProductValue.CUSTOMER_DESIGN_BLANKETS,
      ProductValue.TUMBLERS,
      ProductValue.PHOTO_PORTRAITS,
      ProductValue.SHEETZ_FREAKZ,
    ].includes(this.id);
  }

  get isBatch(): boolean {
    return [
      ProductValue.PLUSHIE_BATCH,
      ProductValue.PILLOW_BATCH,
      ProductValue.KEYCHAIN_BATCH,
    ].includes(this.id);
  }

  public toString(): string {
    return this.name;
  }
}
